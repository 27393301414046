import request from '@/utils/request'

/**
 * 获取文化培训课程列表
 * @param {*} data 
 */
export function list(data) {
    return request({
        url: '/v2/culture/course.index/pagelist',
        method: 'post',
        params: data
    })
}

/**
 * 获取文化培训课程详情
 * @param {*} data 
 */
export function detail(data) {
    return request({
        url: '/v2/culture/course.index/detail',
        method: 'post',
        params: data
    })
}

/**
 * 获取课程章节列表
 * @param {*} data 
 */
export function chapters(data) {
    return request({
        url: '/v2/culture/course.chapter/list',
        method: 'post',
        params: data
    })
}
/**
 * 报名培训课程
 * @param {*} data 
 */
export function signup(data) {
    return request({
        url: '/v2/culture/member.course/signup',
        method: 'post',
        params: data
    })
}
/**
 * 检查用户是否报名培训
 * @param {*} data 
 */
export function checkSignup(data) {
    return request({
        url: '/v2/culture/member.course/checkSignup',
        method: 'post',
        params: data
    })
}
/**
 * 上报用户学习章节
 * @param {*} data 
 */
export function learnChapter(data) {
    return request({
        url: '/v2/culture/member.course/learnChapter',
        method: 'post',
        params: data
    })
}
/**
 * 获取用户培训课程列表
 * @param {*} data 
 */
export function mylist(data) {
    return request({
        url: '/v2/culture/member.course/mylist',
        method: 'post',
        params: data
    })
}

/**
 * 取消报名
 * 参数名	必选	类型	说明
    access_token	是	string	token
    course_id	是	int	课程ID
 * @param {*} data 
 */
export function cancelSignup(data) {
    return request({
        url: '/v2/culture/member.course/cancelSignup',
        method: 'post',
        params: data
    })
}
/**
 * 获取用户课程学习列表
 * access_token	是	string	token值
aid	是	int	馆方编号
page	否	int	页码，默认1
page_size	否	int	每页条数，默认10
 * @param {*} data 
 */
export function learn(data) {
    return request({
        url: '/v2/culture/member.course/learn',
        method: 'post',
        params: data
    })
}
