<template>
  <div class="share_box">
    <div class="share_box_detail" @click="share('weixin')">
      <img
              class="share_box_detail_icon"
              src="https://image.bookgo.com.cn/zb/mine/weChat_icon.png"
      />
      <div class="share_box_detail_title">朋友圈</div>
    </div>
    <div class="share_box_detail" @click="share('qzone')">
      <img
              class="share_box_detail_icon"
              src="https://image.bookgo.com.cn/zb/mine/qqSpace_icon.png"
      />
      <div class="share_box_detail_title">QQ空间</div>
    </div>
    <div class="share_box_detail" @click="share('sina')">
      <img
              class="share_box_detail_icon"
              src="https://image.bookgo.com.cn/zb/mine/sina_icon.png"
      />
      <div class="share_box_detail_title">新浪微博</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'share',
  props: {
    url: {
      type: String,
      default: document.location.href
    },
    title: {
      type: String,
      default: '标题'
    },
    pic: {
      type: String,
      default: ''
    },
    summary: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
    }
  }, methods: {
    share(type) {
      const protocol = document.location.protocol;
      const host = document.location.host;
      var url = encodeURIComponent(protocol+'//'+host+this.url);
      //qq空间分享接口
      if (type == 'qzone') {
        window.open('https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url='+url+'&title='+this.title+'&pics='+this.pic+'&summary='+this.summary)
      }
      //新浪微博接口的传参
      if (type == 'sina') {
        window.open('http://service.weibo.com/share/share.php?url='+url+'&title='+this.title+'&pic='+this.pic+'&appkey=微博平台申请的key');
      }
      //微信分享
      var targetUrl = 'http://qr.liantu.com/api.php?text=' + url;
      window.open(targetUrl, 'weixin', 'height=320, width=320')

    }
  }
}
</script>

<style lang="css" scoped>
</style>