<template>
  <div class="about">
    <h1>This is an about page</h1>

    <Share></Share>
  </div>
</template>



<script>
import { list } from "@/api/course"
// eslint-disable-next-line no-unused-vars
import { setToken,getToken } from "@/utils/auth"
import Share from "@/components/share"
export default {
  name: 'About',
  components:{Share},
  data() {
    return {

    }
  },
  mounted() {
    console.info(process.env.VUE_APP_AID)
    setToken('eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1aWQiOjY5NDUzLCJmcm9tX2FpZCI6IjUwMDEiLCJyZWFkZXJzX2lkIjowLCJyZWFkZXJfaWQiOjAsInJkcGFzcyI6IiIsImxvZ2luX3RpbWUiOjE1OTkwMTUwNjR9.-YRxXjNLef0v-fHKa7Hab6G2OmutnKDy60TE9N5XgZg')
    console.info(getToken())
    list({aid:process.env.VUE_APP_AID}).then(res => {
      console.info(res)
    })
  }
}
</script>

<style lang="css" scoped>
</style>


